@import url('https://fonts.cdnfonts.com/css/cascadia-code');
/*@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital@0;1&display=swap');*/

body {
	--background-color: white;
	--syntax-color: #24292f;
	--value-color: #0a3069;
	--alt-value-color: #0550ae;
	--visited-link: #551A8B;
	--unvisited-link: #0000FF;
	--property-name-color: #116329;
	--comma-color: #24292f;
}

/*@media (prefers-color-scheme: light) {*/
@media (prefers-color-scheme: dark) {
	body {
		--background-color: #0d1117;
		--syntax-color: white;
		--value-color: #a5d6ff;
		--alt-value-color: #38a0fc;
		--visited-link: #bea5ff;
		--unvisited-link: #1E90FF;
		--property-name-color: #7ee787;
		--comma-color: white;
	}
}

body {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	align-content: center;
	min-height: 100vh;
	font-size: clamp(17px, 4.3vw, 40px);
	/*font-family: 'Cascadia Code', sans-serif;*/
	font-family: 'Cascadia Code', 'Roboto Mono', 'Poppins', 'Ubuntu', sans-serif;
	background-color: var(--background-color);
}

body * {
	flex: 1 100%;
}

body > * {
	padding-left: 1vw;
}
