.plus-one {
	padding-left: 3vw;
}

.syntax {
	color: var(--syntax-color);
	font-style: italic;
}

.value {
	color: var(--value-color);
}

.value > a:visited {
	color: var(--visited-link);
}

.value > a {
	color: var(--unvisited-link);
}

.alt-value > .value {
	color: var(--alt-value-color);
}

.property-name {
	color: var(--property-name-color);
	font-style: italic;
}

.comma {
	color: var(--comma-color);
	font-style: italic;
}
